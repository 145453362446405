import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import styled from "styled-components"
import { accent } from "../utilities"

const PortfolioRepeater = ({ props }) => {
  return (
    <StPortfolioCards>
      {props.map((item, index) => (
        <Card
          key={item.fields.slug}
          to={`/portfolio${item.fields.slug}`}
          className="wow fadeInUp"
         
        >
          <div className="card__inner" />
          <ImagePanel>
            <div className="image-panel__inner" />
            <Img fluid={item.frontmatter.cardImage400.childImageSharp.fluid} />
          </ImagePanel>
          {/* <DetailsPanel>
            <Title>{item.frontmatter.title}</Title>
            <ShortDesc>{item.frontmatter.shortDescription}</ShortDesc>
          <p>{item.frontmatter.location}</p> 
          </DetailsPanel> */}
        </Card>
      ))}
    </StPortfolioCards>
  )
}

const StPortfolioCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin-top: 100px;
`

// const DetailsPanel = styled.div`
//   padding: 0 10px;
//   text-align: center;
// `

const Card = styled(Link)`
  display: block;
  margin-bottom: 50px;
  color: #000;
  text-decoration: none;
  padding: 5px;
  border: 1px solid #ccc;
  position: relative;
  transition: box-shadow 0.6s;
  backface-visibility: hidden;
  width: calc(50% - 20px);
  margin:0 10px 10px;

 

  @media (min-width: 1001px) {
    width: calc(25% - 20px);
    margin:0 10px 10px;
  
  }

  &:hover {
    color: #000;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.15);

    img {
      transform: scale(1.025);
    }

    &:before,
    &:after {
      opacity: 1;
      transition: width 0.6s ease-out, height 0.6s ease-out, opacity 1s;
    }

    .card__inner {
      &:before,
      &:after {
        opacity: 1;
        transition: width 0.6s 0.2s ease-out, height 0.6s 0.2s ease-out,
          opacity 1s;
      }
      &:before {
        width: 100%;
      }

      &:after {
        height: 100%;
      }
    }

    &:before {
      width: 100%;
    }

    &:after {
      height: 100%;
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 0;
    background: ${accent};
    width: 1px;
    height: 1px;
    opacity: 0;
    transition: all 0.3s;
  }

  &:before {
    top: 0;
    right: 0;
  }

  &:after {
    top: 0;
    right: 0;
  }

  .card__inner {
    &:before,
    &:after {
      content: "";
      position: absolute;
      background: ${accent};
      width: 1px;
      height: 1px;
      transition: all 0.3s;
      opacity: 0;
    }

    &:before {
      bottom: 0;
      left: 0;
    }

    &:after {
      bottom: 0;
      left: 0;
    }
  }

  
`

const ImagePanel = styled.div`
  position: relative;
  overflow: hidden;
  .gatsby-image-wrapper {
    & > img {
    }

    & > div {
      padding-bottom: 50% !important;
    }
  }

  picture img {
    transition: opacity 500ms ease 0s, transform 0.6s !important;
  }

  &:hover {
    &:before,
    &:after {
      opacity: 0.5;
      transition: width 0.6s ease-out, height 0.6s ease-out, opacity 1s;
      z-index: 1;
    }

    .image-panel__inner {
      &:before,
      &:after {
        opacity: 0.5;
        transition: width 0.6s 0.2s ease-out, height 0.6s 0.2s ease-out,
          opacity 1s;
      }
      &:before {
        width: 100%;
      }

      &:after {
        height: 100%;
      }
    }

    &:before {
      width: 100%;
    }

    &:after {
      height: 100%;
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 0;
    background: ${accent};
    width: 1px;
    height: 1px;
    opacity: 0;
    transition: all 0.3s;
    bottom: 0;
    left: 0;
  }

  .image-panel__inner {
    &:before,
    &:after {
      content: "";
      position: absolute;
      background: ${accent};
      width: 1px;
      height: 1px;
      transition: all 0.3s;
      opacity: 0;
      z-index: 1;
      top: 0;
      right: 0;
    }
  }
`

// const Title = styled.h3`
//   ${MuliLight}
// `

// const ShortDesc = styled.p`
//   font-size: 0.8rem;
//   margin-bottom: 0;
//   color: #555;
// `

export default PortfolioRepeater
