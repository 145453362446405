import React from "react"
import { graphql } from "gatsby"
import PortfolioRepeater from "../components/portfolioRepeater"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ProjectSectorPage = ({ data }) => {
  const projects = data.projects.nodes;
  return (
    <Layout>
       <SEO
        title={data.pageData.frontmatter.title}
        description={data.pageData.frontmatter.metaDescription}
      />
       <h1 style={{textAlign:"center",textTransform:"uppercase"}}>{data.pageData.frontmatter.title}</h1>
      {data.pageData.html.length > 3 && (
         <div dangerouslySetInnerHTML={{ __html: data.pageData.html }}/>
      )
      }
      <PortfolioRepeater props={projects} />
    </Layout>
  )
}

export const PROJECT_SECTOR_QUERY = graphql`
query($sector: String!) {
  projects :allMarkdownRemark(filter:{frontmatter: {templateKey: {eq: "portfolio-item"},sector:{eq: $sector}}},sort: { fields: [frontmatter___displayOrder], order: [ASC]}){
    nodes {
        fields {
          slug
        }
        frontmatter {
          title
          shortDescription
          location
          sector
          cardImage400: cardImage {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
  }
  pageData: markdownRemark(frontmatter: {templateKey: {eq: $sector}}){
    frontmatter{
      title
      metaDescription
    }
    html
  }
}
`

export default ProjectSectorPage
